import { getCmsPathPartsAsArray } from '../pathCheck'

export const updateEnrichment = (path, uniformContext, enrStr = 10) => {
  try {
    const [cat, key] = getCmsPathPartsAsArray(path)
    uniformContext.update({
      enrichments: [
        {
          str: enrStr,
          cat,
          key,
        },
      ],
    })
  } catch (e) {
    console.error('Error adding enrichment', e)
  }
}
