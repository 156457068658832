export const hullabalookTrackingEvents = [
  {
    label: 'back_to_gallery',
    getEvent: () => {
      return {
        eventInfo: {
          eventName: 'getTheLook_interaction',
          eventAction: 'Get The Look : Back to Gallery',
        },
        attributes: {
          interaction: 'backToGallery',
          roomName: undefined, // hullabalook not added
        },
      }
    },
  },
  {
    label: 'product-add-to-room',
    getEvent: (eventValue) => {
      if (!eventValue.ident) return null
      return {
        eventInfo: {
          eventName: `roomCreator_interaction`,
          eventAction: 'Room Creator : Product Added to Room',
        },
        attributes: {
          interaction: 'product-add-to-room',
          products: [
            {
              id: eventValue.ident,
              name: eventValue.name,
              price: eventValue.price,
            },
          ],
        },
      }
    },
  },
  {
    label: 'product-remove-from-room',
    getEvent: (eventValue) => {
      if (!eventValue.ident) return null
      return {
        eventInfo: {
          eventName: `roomCreator_interaction`,
          eventAction: 'Room Creator : Product Removed from Room',
        },
        attributes: {
          interaction: 'product-remove-from-room',
          products: [
            {
              id: eventValue.ident,
              name: eventValue.name, // hullabalook not added yet
              price: eventValue.price,
            },
          ],
        },
      }
    },
  },
  {
    label: 'room-width-change',
    getEvent: () => {
      return {
        eventInfo: {
          eventName: 'roomCreator_interaction',
          eventAction: 'Room Creator : Room Width Change',
        },
        attributes: {
          interaction: 'room-width-change',
        },
      }
    },
  },
  {
    label: 'room-height-change',
    getEvent: () => {
      return {
        eventInfo: {
          eventName: 'roomCreator_interaction',
          eventAction: 'Room Creator : Room Height Change',
        },
        attributes: {
          interaction: 'room-height-change',
        },
      }
    },
  },
  {
    label: 'colour-wall-colour',
    getEvent: (eventValue) => {
      if (!eventValue.hex) return null
      return {
        eventInfo: {
          eventName: 'roomCreator_interaction',
          eventAction: 'Room Creator : Room Wall Colour Change',
        },
        attributes: {
          interaction: 'room-wall-colour-change',
          roomColour: eventValue.hex,
        },
      }
    },
  },
  {
    label: 'room-floor-colour-change',
    getEvent: (eventValue) => {
      if (!eventValue.hex) return null
      return {
        eventInfo: {
          eventName: 'roomCreator_interaction',
          eventAction: 'Room Creator : Room Floor Colour Change',
        },
        attributes: {
          interaction: 'room-floor-colour-change',
          roomColour: eventValue.hex,
        },
      }
    },
  },
  {
    label: 'room-image-download',
    getEvent: () => {
      return {
        eventInfo: {
          eventName: 'roomCreator_interaction',
          eventAction: 'Room Creator : Download Image',
        },
        attributes: {
          interaction: 'room-image-download',
        },
      }
    },
  },
  {
    label: 'room-share',
    getEvent: () => {
      return {
        eventInfo: {
          eventName: 'roomCreator_interaction',
          eventAction: 'Room Creator : Share Room',
        },
        attributes: {
          interaction: 'room-share',
        },
      }
    },
  },
  {
    label: 'category-change',
    getEvent: (eventValue) => {
      if (!eventValue.selectedTag) return null
      return {
        eventInfo: {
          eventName: 'roomCreator_interaction',
          eventAction: 'Room Creator : Category Change',
        },
        attributes: {
          interaction: 'category-change',
          category: eventValue.selectedTag,
        },
      }
    },
  },
  {
    label: 'subcategory-change',
    getEvent: (eventValue) => {
      if (!eventValue.selectedTag) return null
      return {
        eventInfo: {
          eventName: 'roomCreator_interaction',
          eventAction: 'Room Creator : Sub Category Change',
        },
        attributes: {
          interaction: 'subcategory-change',
          subCategory: eventValue.selectedTag,
        },
      }
    },
  },
  {
    // Click on search field - tracks on keypress not click
    label: 'text-search',
    getEvent: () => {
      return {
        eventInfo: {
          eventName: 'roomCreator_interaction',
          eventAction: 'Room Creator : Search Field Clicked',
        },
        attributes: {
          interaction: 'search-field-interaction',
        },
      }
    },
  },
  {
    label: 'filter-use',
    getEvent: (eventValue) => {
      return {
        eventInfo: {
          eventName: 'roomCreator_interaction',
          eventAction: 'Room Creator : Filter',
        },
        attributes: {
          interaction: 'filter',
          filterType: eventValue.filterType,
          filterValue: eventValue.filterValue,
        },
      }
    },
  },
  {
    label: 'colour-select',
    getEvent: (eventValue) => {
      return {
        eventInfo: {
          eventName: 'getTheLook_interaction',
          eventAction: 'Get The Look : Filter Applied',
        },
        attributes: {
          interaction: 'filterApplied',
          roomName: undefined, // hulla to add
          filterType: 'colour',
          filterDetails: eventValue.colour,
        },
      }
    },
  },
  {
    label: 'price-select',
    getEvent: (eventValue) => {
      return {
        eventInfo: {
          eventName: 'getTheLook_interaction',
          eventAction: 'Get The Look : Filter Applied',
        },
        attributes: {
          interaction: 'filterApplied',
          roomName: undefined, // hulla to add
          filterType: 'price',
          filterDetails: `${eventValue.min}-${eventValue.max}`,
        },
      }
    },
  },
  {
    label: 'gallery-clickthrough-to-gtl',
    getEvent: (eventValue) => {
      return {
        eventInfo: {
          eventName: 'getTheLook_interaction',
          eventAction: 'Get The Look : Room Selected',
        },
        attributes: {
          interaction: 'roomSelected',
          appType: 'gallery',
          roomName: eventValue.image_name,
        },
      }
    },
  },
  {
    label: 'page-loaded',
    getEvent: (eventValue) => {
      return {
        eventInfo: {
          eventName: 'getTheLook_impression',
          eventAction: 'Get The Look : Impression',
        },
        attributes: {
          roomName: eventValue.room, // Hulla not added
        },
      }
    },
  },
  {
    label: 'hotspot-selected',
    getEvent: (eventValue) => {
      return {
        eventInfo: {
          eventName: 'getTheLook_interaction',
          eventAction: 'Get The Look : Hotspot Clicked',
        },
        attributes: {
          interaction: 'hotspot',
          roomName: eventValue.room,
          productType: eventValue.category,
        },
      }
    },
  },
  {
    label: 'quickview-opened',
    getEvent: (eventValue) => {
      return {
        eventInfo: {
          eventName: 'getTheLook_interaction',
          eventAction: 'Get The Look : Product Clicked',
        },
        attributes: {
          interaction: 'product',
          roomName: eventValue.room,
          clickSource: undefined, // Hullabalook not added yet
          products: [
            {
              id: eventValue.id,
              name: eventValue.name,
              price: eventValue.price,
            },
          ],
        },
      }
    },
  },
  {
    label: 'open-main-app',
    getEvent: (eventValue) => {
      return {
        eventInfo: {
          eventName: 'getTheLook_interaction',
          eventAction: 'Get The Look : Want to See More Products',
        },
        attributes: {
          interaction: 'seeMoreProducts',
          roomName: eventValue.room,
        },
      }
    },
  },
  {
    // Get the Look mini app - click 'view products in the look' - hullabalook not added yet
    label: 'view-in-look',
    getEvent: (eventValue) => {
      return {
        eventInfo: {
          eventName: 'getTheLook_interaction',
          eventAction: 'Get The Look : View Products In the Look',
        },
        attributes: {
          interaction: 'viewProductsInTheLook',
          roomName: eventValue.room,
        },
      }
    },
  },
  {
    // Get the Look mini app - when the user clicks on 'View full product description' - missing some tracking events
    label: 'clickthrough-to-pdp',
    getEvent: (eventValue) => {
      return {
        eventInfo: {
          eventName: 'getTheLook_interaction',
          eventAction: 'Get The Look : View Full Product Description',
        },
        attributes: {
          interaction: 'viewFullProductDescription',
          roomName: eventValue.room,
          products: [
            {
              id: eventValue.id,
              name: eventValue.name,
              price: eventValue.price,
            },
          ],
        },
      }
    },
  },
]
