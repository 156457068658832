import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import EventComponents, { components } from '@sainsburys-tech/bolt-cms-components'
import { Alert } from '@sainsburys-tech/bolt'
import { agnostify } from '@sainsburys-tech/boltui-utils'
import { push } from 'connected-react-router'
import { UniformTracking } from '../../components/UniformTracking/UniformTracking'

import * as Styled from './CmsPageContainer.styles'
import {
  getContentAreas,
  validAjaxLink,
  getTemplateLevelAttrs,
  getSiteSection,
  getCMSAreas,
} from '../../helpers/ContentHelper'
import { isHomepagePath, isHullabalookCmsPath } from '../../helpers/pathCheck'
import { isArgos, getBrandName, getHomepageComponents } from '../../helpers/brandHelper'
import { loadCmsData as loadCmsDataAction } from '../../actions/cmsAppActions'
import { addHullaEmbed, removeHullaEmbed } from '../../helpers/HullabalookHelper'
import PageNotFoundWrapper from '../../components/PageNotFoundWrapper/PageNotFoundWrapper'
import AnchorNavProvider from '../../components/AnchorNavProvider/AnchorNavProvider'
import PageSkeleton from '../../components/Skeletons/PageSkeleton'

import ScrollPositionConsumer from '../../helpers/scrollPositionContext'

export class CmsPageContainer extends Component {
  constructor(props, context) {
    super(props, context)
    this.onCmsClick = this.onCmsClick.bind(this)
    this.onAnchorEntry = this.onAnchorEntry.bind(this)
    this.viewChecker = this.viewChecker.bind(this)
    this.bigNav = []
    this.navCount = 0
    this.state = {
      activeSection: {},
      anchorHashLoaded: false,
      bottomDeadZone: 0,
      navActivePos: 'above',
      navObject: [],
      topDeadZone: 0,
      hasError: false,
    }
  }

  componentDidMount() {
    const { location, history, variantTests, historyData, loadCmsData } = this.props
    if (history && history.action === 'PUSH') {
      loadCmsData({ location: location.pathname, historyData, resetData: true, variantTests })
    }

    const path = history?.location?.pathname
    if (isHullabalookCmsPath(path) && document.getElementById('hulla') !== null) {
      const onError = () => {
        this.setState({
          hasError: true,
        })
      }
      const clickOriginParam = getSiteSection(location.pathname)
      addHullaEmbed(onError, clickOriginParam)
    }
  }

  componentDidUpdate(prevProps) {
    const { history, isLoading, location, variantTests, loadCmsData, historyData = {} } = this.props
    const { anchorHashLoaded } = this.state

    if (history.location.pathname !== prevProps.location.pathname) {
      loadCmsData({ location: location.pathname, resetData: true, historyData, variantTests })
    }

    const anchorHash = location && location.hash && document.getElementById(location.hash.substring(1))
    if (anchorHash && !isLoading && !anchorHashLoaded) {
      this.setState({ anchorHashLoaded: true }, () => {
        window.scrollTo(0, anchorHash.offsetTop)
      })
    }
  }

  componentWillUnmount() {
    removeHullaEmbed()
  }

  onCmsClick(event, linkUrl) {
    const isTargetBlank = event?.currentTarget?.target === '_blank'
    if (!linkUrl || !linkUrl.includes('argos.co.uk') || isTargetBlank) return
    const ajaxLinkUrl = validAjaxLink(linkUrl)
    if (ajaxLinkUrl) {
      event.preventDefault()
      const { history } = this.props
      history.push(ajaxLinkUrl)
    } else window.location.href = agnostify(linkUrl)
  }

  onAnchorEntry(anchorNavigation, offsetTop, anchorName, isStart) {
    if (anchorNavigation) {
      this.navCount = anchorNavigation.length
      this.anchorNavigationStates = {
        topDeadZone: offsetTop + 59,
        anchorNavigation,
      }
    } else {
      const position = isStart ? 'top' : 'bottom'
      const newObject = { anchorName, [position]: offsetTop }
      this.bigNav.push(newObject)
      const totalAnchors = this.navCount * 2
      if (this.bigNav.length === totalAnchors) {
        let navObject = []
        for (let i = 0; i < this.bigNav.length; i += 1) {
          const anchor = this.bigNav[i]
          if (!navObject[anchor.anchorName]) {
            navObject[anchor.anchorName] = { anchorName: anchor.anchorName }
          }
          if (anchor.top) navObject[anchor.anchorName].top = anchor.top
          if (anchor.bottom) navObject[anchor.anchorName].bottom = anchor.bottom
        }
        navObject = Object.values(navObject)
        const bottomDeadZone = [...navObject].pop().bottom + 89
        this.setState(
          {
            navObject,
            bottomDeadZone,
            ...this.anchorNavigationStates,
          },
          () => {
            this.bigNav = []
            this.bigNav.length = 0
          },
        )
      }
    }
  }

  viewChecker(navObj) {
    this.setState(navObj)
  }

  render() {
    const { cmsData, history } = this.props
    const { navActivePos, activeSection, navObject, topDeadZone, bottomDeadZone } = this.state
    const validData = cmsData && !cmsData.invalidData
    const isCMS404Page = !validData || Object.keys(cmsData).length === 0
    if (isCMS404Page) return <PageNotFoundWrapper history={history} />

    const templateLevelData = cmsData && getTemplateLevelAttrs(cmsData)
    const anchorNavigation = templateLevelData &&
      templateLevelData.hasAnchorNavigation && {
        navActivePos,
        activeSection: activeSection.anchorName,
      }

    const wrapperProps = (context) =>
      anchorNavigation && context
        ? {
            viewChecker: this.viewChecker,
            scrollPosition: context.scrollPosition,
            cmsData,
            navObject,
            navActivePos,
            activeSection,
            topDeadZone,
            bottomDeadZone,
          }
        : {}

    let ProviderWrapper
    if (anchorNavigation) ProviderWrapper = AnchorNavProvider
    else ProviderWrapper = React.Fragment

    const pathName =
      validData && history && history.location && history.location.pathname
        ? history.location.pathname
        : '/default-cms-page'
    const cmsPageName = pathName
      .substr(1)
      .split('/')
      .join('-')

    const isResettingData = cmsData.resettingData
    if (isResettingData) return <PageSkeleton page='cms' />
    const errorMessage =
      'Sorry, we’re experiencing some difficulties with our service right now. Please refresh to try again.'

    const isHomepage = isHomepagePath(pathName)
    const homepageHeading = isHomepage ? `${getBrandName()} homepage` : null
    const homepageComponents = isHomepage ? getHomepageComponents() : null

    const { bodyContentAreas, footerContentAreas } = getCMSAreas(cmsData, pathName)

    return (
      <>
        {this.state.hasError && (
          <Alert kind='error' isCloseable={false}>
            {errorMessage}
          </Alert>
        )}
        <Styled.CmsGlobalStyle />
        {isHomepage && <h1 className='sr-only'>{homepageHeading}</h1>}
        <Styled.CmsContainer className={`container--${cmsPageName} container`}>
          <UniformTracking path={pathName} />
          <EventComponents components={components}>
            <ScrollPositionConsumer>
              {(context) => (
                <ProviderWrapper {...wrapperProps(context)}>
                  {getContentAreas(
                    bodyContentAreas,
                    pathName,
                    isArgos() ? this.onCmsClick.bind(this) : null,
                    anchorNavigation && this.onAnchorEntry.bind(this),
                    anchorNavigation,
                    context && context.scrollPosition,
                  )}
                  <div>{homepageComponents}</div>
                  {!!footerContentAreas.length &&
                    getContentAreas(footerContentAreas, isArgos() ? this.onCmsClick.bind(this) : null)}
                </ProviderWrapper>
              )}
            </ScrollPositionConsumer>
          </EventComponents>
        </Styled.CmsContainer>
      </>
    )
  }
}

const mapStateToProps = (state) => ({
  variantTests: state?.cmsApp?.variantTests,
  cmsData: state?.cmsApp?.cmsData,
  isLoading: state?.cmsApp?.isLoading,
  historyData: state?.cmsApp?.historyData,
})

const mapDispatchToProps = (dispatch) => ({
  loadCmsData: (params) => dispatch(loadCmsDataAction(params)),
  push,
})

CmsPageContainer.propTypes = {
  cmsData: PropTypes.arrayOf(PropTypes.object),
  historyData: PropTypes.objectOf(PropTypes.object),
  isLoading: PropTypes.bool,
  loadCmsData: PropTypes.func.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string,
    hash: PropTypes.string,
  }),
  push: PropTypes.func.isRequired,
  variantTests: PropTypes.arrayOf(PropTypes.object),
  history: PropTypes.shape({
    action: PropTypes.oneOf(['PUSH', 'REPLACE', 'POP']).isRequired,
    location: PropTypes.string,
    push: PropTypes.func.isRequired,
  }),
}

CmsPageContainer.defaultProps = {
  isLoading: false,
  cmsData: [],
  historyData: {
    location: '',
  },
  location: {
    pathname: '',
    hash: '',
  },
  variantTests: [],
  history: {
    location: '',
  },
}

export default connect(mapStateToProps, mapDispatchToProps)(CmsPageContainer)
