import { hullabalookTrackingEvents } from './hullabalookTrackingEvents'
import { pushEventAndTrack } from './TaggingSerializer'
import { isRoomCreatorPath } from './pathCheck'

const addProductsToBasket = async (products, onError, clickOriginParam) => {
  const arrayOfProducts = products.map((product) => Array(product.qty).fill(product.id)).flat()
  const URL = `/basket-api/v1/basket/items`
  try {
    const response = await fetch(URL, {
      method: 'POST',
      body: JSON.stringify({
        productId: arrayOfProducts.shift(),
        quantity: '1',
        bundleItems: arrayOfProducts,
      }),
      headers: {
        'Content-Type': 'application/json',
        cookie: document.cookie,
      },
    })
    response.ok ? (window.location.href = `/basket?clickOrigin=${clickOriginParam}`) : onError()
  } catch (error) {
    console.error(error)
    onError()
  }
}

export const addHullaEmbed = (onError, clickOriginParam) => {
  const isRoomCreator = isRoomCreatorPath(window.location.pathname)
  const pageTemplate = isRoomCreator ? 'roomCreator' : 'getTheLook'
  const script = document.createElement('script')
  script.src = 'https://live.hullabalook.com/static/js/hulla_embed.js'
  script.id = 'hullaEmbedScript'

  const camelCase = (string) => {
    const pascalCase = string.replace(/(_| ){1}([a-z])/g, (g) => g[1].toUpperCase())
    return pascalCase.replace(/(^[A-Z])/g, (g) => g.toLowerCase())
  }
  const replaceAmpersand = (string) => string.replace(/&/g, 'and')

  script.onload = () => {
    // hullabalook tracking
    let textSearchTracked = false
    window.addEventListener('message', (e) => {
      const event = hullabalookTrackingEvents.find(
        (events) =>
          events.label === e.data?.payload?.eventLabel ||
          events.label === e.data?.message?.data?.eventLabel ||
          events.label === e.data?.message?.message,
      )
      if (event) {
        const eventValue = e.data?.payload?.eventValue || e.data?.message?.data
        const trackingEvent = event.getEvent(eventValue)

        // transform attributes
        const roomName = trackingEvent?.attributes?.roomName || false
        const productType = trackingEvent?.attributes?.productType || false
        const category = trackingEvent?.attributes?.category || false
        const subCategory = trackingEvent?.attributes?.subCategory || false

        if (roomName) {
          const transformedAttr = camelCase(roomName)
          trackingEvent.attributes.roomName = transformedAttr
        }
        if (productType) {
          const transformedAttr = camelCase(productType)
          trackingEvent.attributes.productType = transformedAttr
        }
        if (category && category?.includes('&')) {
          const transformedAttr = replaceAmpersand(category)
          trackingEvent.attributes.category = transformedAttr
        }
        if (subCategory && subCategory?.includes('&')) {
          const transformedAttr = replaceAmpersand(subCategory)
          trackingEvent.attributes.subCategory = transformedAttr
        }
        // text-search is sent on every keypress, added this logic so it is tracked only once per page load
        if (event.label === 'text-search') {
          if (!textSearchTracked) {
            textSearchTracked = true
            window.digitalData.track(trackingEvent.eventInfo.eventName, trackingEvent)
          }
        } else if (trackingEvent) window.digitalData.track(trackingEvent.eventInfo.eventName, trackingEvent)
      }
    })

    setTimeout(() => {
      window.addEventListener('message', (e) => {
        const products = e.data?.payload?.cartPayload || e.data?.payload?.cartPayLoad
        if (products && products[0] && products[0].qty) {
          addProductsToBasket(products, onError, clickOriginParam)
        }
        // add to trolley tracking
        const productEvents = e.data?.payload?.eventPayload || e.data?.payload?.eventPayLoad
        const productsArray = []
        const addProductToArray = (event) => {
          // logic to track quantity as hullabalook doesnt send correctly
          const duplicateProduct = productsArray.find((product) => product.id === event.id) || false
          if (duplicateProduct) {
            const index = productsArray.indexOf(duplicateProduct)
            const qty = productsArray[index].quantity || productsArray[index].qty
            productsArray[index] = {
              ...productsArray[index],
              quantity: qty + 1,
            }
          } else {
            productsArray.push({
              id: event.id,
              price: event.price,
              quantity: event.quantity || event.qty,
            })
          }
        }
        // room creator, add to trolley tracking
        if (products && products[0] && products[0].qty && productEvents && isRoomCreator) {
          productEvents.forEach((event) => addProductToArray(event))
          pushEventAndTrack(
            {
              eventInfo: {
                eventName: 'addToTrolley',
                eventAction: 'Add to Trolley',
                interaction: 'addToTrolley',
              },
              attributes: {
                product: productsArray,
                clickOrigin: `${pageTemplate}:addToTrolley`,
              },
            },
            true,
          )
        }
        // Get the Look, add to trolley tracking
        if (products && products[0] && products[0].qty && productEvents && !isRoomCreator) {
          productEvents.forEach((event) => addProductToArray(event))
          pushEventAndTrack(
            {
              eventInfo: {
                eventName: 'addToTrolley',
                eventAction: 'Add to Trolley',
              },
              attributes: {
                interaction: productsArray.length > 1 ? 'addAllToTrolley' : 'addToTrolley',
                roomName: undefined, // Hulla to add
                clickSource: undefined, // Hulla to add
                product: productsArray,
                clickOrigin: undefined, // Hulla to add - 'getTheLook:[ roomName ]:[ clickSource ]:[ interaction ]'
              },
            },
            true,
          )
        }
      })

      window.Hulla.init({
        embed_url: 'https://live.hullabalook.com',
        target_div: 'hulla',
      })
    }, 0)
  }
  document.body.appendChild(script)
}

export const removeHullaEmbed = () => {
  const script = document.getElementById('hullaEmbedScript')
  if (script) {
    document.body.removeChild(script)
  }
}
